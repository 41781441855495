<template>
  <header
    id="header"
    class="main-header header-sticky header-sticky-smart header-style-10  bg-white"
  >
    <div class="header-wrapper sticky-area border-bottom">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-xl">
          <div
            class="header-mobile d-flex d-xl-none flex-fill justify-content-between align-items-center"
          >
            <div
              class="navbar-toggler toggle-icon"
              data-toggle="collapse"
              data-target="#navbar-main-menu"
            >
              <span></span>
            </div>

            <router-link
              to="/"
              class="navbar-brand navbar-brand-mobile text-center align-items-center"
              active-class="active"
              exact
            >
              <img
                style="height:33px"
                src="images/logo.png"
                alt="GridBusiness"
              />
            </router-link>
          </div>
          <div class="collapse navbar-collapse" id="navbar-main-menu">
            <router-link
              to="/"
              class="navbar-brand d-none d-xl-block ml-5 align-items-center"
              active-class="active"
              exact
            >
              <img
                style="height:33px"
                src="images/logo.png"
                alt="GridBusiness"
              />
            </router-link>

            <ul class="navbar-nav mr-4 ml-auto">
              <li class="nav-item">
                <router-link
              to="/directorySearch"
              class="nav-link"
              active-class="active"
              exact
            >
             Directory Search

            </router-link>
               
              </li>

              <li class="nav-item">
                <a class="nav-link" href="#"
                  >Documentation
                  <span class="caret"><i class="fas fa-angle-down"></i></span
                ></a>
                <ul class="sub-menu x-animated x-fadeInUp text-left">
                  <li class="nav-item mt-2 text-left">
                    <a class="nav-link text-left" target="_blank" href="/documentation"
                      >API Documentation</a
                    >
                  </li>

                  <li class="nav-item text-left">
                    <a class="nav-link text-left" href="#">FAQ</a>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="header-customize-item">
              <a @click="logout"  style="cursor:pointer" class="link">
                <img
                  style="width:20px;height:20px"
                  v-if="!isLoading"
                  :src="user ? user.profileImageUrl : null"
                  :alt="user ? user.fullName : null"
                  class="rounded-circle"
                />
             
                <i class="fas fa-circle-notch fa-spin" v-if="isLoading"></i>
                <span v-if="!isLoading" class="ml-1"> Sign out</span>
                <span v-if="isLoading"> Signing out...</span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",

  data() {
    return {
      success: null,
      error: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),

    logout() {
      this.isLoading = true;
      this.sendLogoutRequest()
        .then(() => {
          this.isLoading = false;
          this.$store.commit("setUserData", null);
          this.$router.push("/login").catch(() => {});
        })
        .catch((error) => {
          this.error = "Internal server error";
          console.log(error.response);
        });
    },
  },
};
</script>
