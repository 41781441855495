<template>
    <transition name="fade">
           <div class="container">
               
                    <div class="page-content-wrapper d-flex flex-column justify-content-center">
                        <div class="mb-7  text-center">
                         
                              <span class="icon icon-map-marker-crossed"><i class="fas fa-empty-set fa-4x"></i></span>
                            
                        </div>
                        <div class="mb-7 text-center">
                            <h3 class="mb-7 text-center">{{title}}</h3>
                            <div class="text-gray text-center">{{message}}.
                            </div>
                        </div>


                    </div>
                </div>
         
     
    </transition>

</template>


<script>
    export default {
        name: 'NoResult',
        props: ["title", "message"],
    }
</script>


<style scoped>
    /* css class for the transition */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>