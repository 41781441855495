<template>
    <div class="sidebar border-right">
        <div class="container-fluid">
            <div class="user-profile media align-items-center mb-6">
                <div class="image mr-3">
                    <img :src="user ? user.profileImageUrl : null" :alt="user ? user.firstName : null"
                        class="card rounded-circle image-box-style-card box-shadow-hover p-1"
                                style="border:1px solid #CCCCCC !important;border-radius: 50%;" />
                </div>
                <div class="media-body lh-14">
                    <span class="text-dark d-block font-size-md">Welcome,</span>
                    <span class="mb-0 h5">{{ user ? user.firstName  : null}}</span>
                </div>
            </div>
            <ul class="list-group list-group-flush list-group-borderless">
                <li class="list-group-item p-0 mb-2 lh-15">
                    <router-link to="/" class="d-flex align-items-center link-hover-dark-primary font-size-md"
                        active-class="active" exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-tachometer-alt"></i></span>
                        <span>Dashboard</span>
                    </router-link>
                </li>

                <li class="list-group-item p-0 mb-2 lh-15">
                    <router-link v-if="user.hasBusiness" :to="{ name: 'MyBusiness', params: { partnerId: user.activeBusiness.partnerId }}" 
                        class="d-flex align-items-center link-hover-dark-primary font-size-md" active-class="active"
                        exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-building"></i></span>
                        <span>My Business</span>
                    </router-link>

                    <router-link to="/createBusiness"
                        class="d-flex align-items-center link-hover-dark-primary font-size-md" active-class="active"
                        v-else exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-plus"></i></span>
                        <span>New Business</span>
                    </router-link>

                </li>
                <li v-if="user.hasBusiness" class="list-group-item p-0 mb-2 lh-15">

               
                        <router-link  :to="{ name: 'MyContact', params: { partnerId: user.activeBusiness.partnerId }}" 
                        class="d-flex align-items-center link-hover-dark-primary font-size-md" active-class="active"
                        exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-users"></i></span>
                        <span>Business Contacts</span>
                    </router-link>

                </li>

                <li v-if="user.hasBusiness" class="list-group-item p-0 mb-2 lh-15">

                    <router-link  :to="{ name: 'MySubscription', params: { partnerId: user.activeBusiness.partnerId }}" 
                        class="d-flex align-items-center link-hover-dark-primary font-size-md" active-class="active"
                        exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-gift"></i></span>
                        <span>Subscription</span>
                    </router-link>

                </li>

                <li v-if="user.hasBusiness" class="list-group-item p-0 mb-2 lh-15">

                    <router-link  :to="{ name: 'MyPayment', params: { partnerId: user.activeBusiness.partnerId }}"
                        class="d-flex align-items-center link-hover-dark-primary font-size-md" active-class="active"
                        exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-receipt"></i></span>
                        <span>Payments & Invoice</span>
                    </router-link>

                </li>



                <li v-if="user.hasBusiness" class="list-group-item p-0 mb-2 lh-15">

                    <router-link  :to="{ name: 'MySetting', params: { partnerId: user.activeBusiness.partnerId }}" class="d-flex align-items-center link-hover-dark-primary font-size-md"
                        active-class="active" exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-cog"></i></span>
                        <span>Settings</span>
                    </router-link>

                </li>

                <li class="list-group-item p-0 mb-2 lh-15">

                    <router-link to="/myProfile" class="d-flex align-items-center link-hover-dark-primary font-size-md"
                        active-class="active" exact>
                        <span class="d-inline-block mr-3"><i class="fal fa-user"></i></span>
                        <span>My Profile</span>
                    </router-link>

                </li>

                <li class="list-group-item p-0 mb-2 lh-15">
                     <a @click="logout" style="cursor:pointer" class="d-flex align-items-center link-hover-dark-primary font-size-md"
                        >
                        <span class="d-inline-block mr-3">
                            <i class="fal fa-sign-out" v-if="!isLoading"></i>
                            <i class="fas fa-circle-notch fa-spin" v-if="isLoading"></i>
                        </span>
                        <span v-if="!isLoading"> Sign out</span>
                        <span v-if="isLoading"> Signing out...</span>
                    </a>
                   
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";

    export default {
        name: "LeftSideBar",

        data() {
            return {
                success: null,
                error: null,
                isLoading: false,
            };
        },
        mounted() {},
        computed: {

            ...mapGetters("auth", ["user"]),
        },
        methods: {
            ...mapActions("auth", ["sendLogoutRequest"]),

            logout() {
                this.isLoading = true;
                this.sendLogoutRequest()
                    .then(() => {
                        this.isLoading = false;
                        this.$store.commit("setUserData", null);
                        this.$router.push("/login").catch(() => {});
                    })
                    .catch((error) => {
                        this.error = "Internal server error";
                        console.log(error.response);
                    });
            },
        },
    };
</script>
<style scoped>
    .active {
        color: #36beb0 !important;
    }
</style>