<template>
    <div class="copy-right text-center pb-2 pt-2" style="position:relative; bottom:2px;right:0;left:0">
        &copy; {{ new Date().getFullYear() }} Grid Business. All Rights Reserved.
    </div>
</template>

<script>
    export default {
        name: "FooterBar",


        computed: {

        },
        mounted() {},
        methods: {


        },
    };
</script>