<template>
    <popup-modal ref="popup">
        <div class="card p-2  border-0" style="border:none!important">
            <div class="card-title border-bottom pb-0">
                <h2 style="margin-top: 0;text-transform: none;">{{ title }}</h2>
            </div>
            <div class="card-body border-0">
                <p style="text-transform: none;">{{ message }}</p>
              
            </div>
            <div class="mt-1 bg-white">
              <div class="btns">
                    <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
                    <button class="ok-btn" @click="_confirm">{{ okButton }}</button>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<!-- components/ConfirmDialogue.vue -->

<script>
    import PopupModal from './PopupModal'

    export default {
        name: 'ConfirmDialogue',

        components: {
            PopupModal
        },

        data: () => ({
            // Parameters that change depending on the type of dialogue
            title: undefined,
            message: undefined, // Main text content
            okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
            cancelButton: 'Go Back', // text for cancel button

            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
        }),

        methods: {
            show(opts = {}) {
                this.title = opts.title
                this.message = opts.message
                this.okButton = opts.okButton
                if (opts.cancelButton) {
                    this.cancelButton = opts.cancelButton
                }
                // Once we set our config, we tell the popup modal to open
                this.$refs.popup.open()
                // Return promise so the caller can get results
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve
                    this.rejectPromise = reject
                })
            },

            _confirm() {
                this.$refs.popup.close()
                this.resolvePromise(true)
            },

            _cancel() {
                this.$refs.popup.close()
                this.resolvePromise(false)
                // Or you can throw an error
                // this.rejectPromise(new Error('User cancelled the dialogue'))
            },
        },
    }
</script>
<!-- components/ConfirmDialogue.vue -->

<style scoped>
    .btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ok-btn {
        padding: 0.5em 1em;
        background-color: #d5eae7;
        color: #ff5a5f;
        border: 2px solid #ff5a5f;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        text-transform: none;
        cursor: pointer;
    }

    .cancel-btn {
        padding: 0.5em 1em;
        background-color: #d5eae7;
        color: #35907f;
        border: 2px solid #0ec5a4;
        border-radius: 5px;
        font-weight: bold;
        font-size: 16px;
        text-transform: none;
        cursor: pointer;
    }
</style>